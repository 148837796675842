import "core-js/stable";
import "regenerator-runtime/runtime";

//import './js/config.js';

// Load in vendor scripts (jquery loaded by Webpack directly);
import 'bootstrap';

// Load in styles
import './scss/styles.scss';

import './js/counter.js';
import './js/cookie-consent';
import './js/map-interact.js';
import './js/image-gallery.js';
//import './js/scroll.js';


// Load in custom JS modules below
//import './js/cookie-consent.js';

//import './js/recaptcha-v3';

// Conditional dynamic imports

// Webpack will store those modules in separate JS files and
// load them on demand, keeping payload down.

//if (document.querySelectorAll('.print-btn').length > 0) {
//    import('./js/print-buttons');
//}


