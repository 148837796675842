// *****************************************
// ************* Image Gallery *************
// *****************************************

import * as $ from 'jquery'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

const sizes = [320, 480, 640, 720, 768, 900, 1024, 1140, 1280, 1440, 1640, 1920, 2280, 3840];
var lightBox;

var prevX,
    prevY,
    resizeTimer;

// Class to represent the individual images
class galleryItem {

    constructor($imageLink, index) {
        // set the index
        $imageLink.attr('data-index', index);
        // add props
        this.url = $imageLink.attr('href');
        this.width = $imageLink.data('width');
        this.height = $imageLink.data('height');
        this.title = $imageLink.find('figcaption').text();
    }

    // By hijacking the getters we always give up-to-date info when photoswipe asks for it
    get w() { return getClosestSize(Math.max($(window).width(), $(window).height()) * (window.devicePixelRatio || 1)); }
    get h() { return this.w * this.aspectRatio; }
    get src() { return this.url + '?width=' + this.w + '&format=jpg&quality=80'; }

    get aspectRatio() {
        return this.height / this.width;
    }
}

// From a given width, get the closest size from the sizes array
const getClosestSize = (size) => {
    var closest = sizes.reduce(function (prev, curr) {
        return (Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev);
    });
    return closest;
}

// get the gallery items from a gallery
const getGalleryItems = ($gallery) => {
    let items = [];
    $gallery.find('a').each(function (x) {
        items.push(new galleryItem($(this), x));
    });

    // EXPERIMENTAL
    //preload for the current viewport size
    window.onload = () => {
        items.forEach((item) => {
            var img = new Image();
            img.src = item.src;
        });
    }

    return items;
}

// get the position of the thumbnail (to enable fancy animation)
const getThumbPosition = (thumbnail) => {
    var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
    var rect = thumbnail.getBoundingClientRect();
    return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
}

function initGallery($gallery) {

    var items = getGalleryItems($gallery);

    $gallery.on('click', 'a', function (event) {

        event.preventDefault();

        var $me = $(this);
        var $index = $me.data('index');

        var options = {
            loop: false,
            index: $index,
            bgOpacity: 0.95,
            showHideOpacity: true,
            preload: [1, 1],
            getThumbBoundsFn: () => getThumbPosition($me[0])
        }

        lightBox = new PhotoSwipe($('.pswp')[0], PhotoSwipeUI_Default, items, options);

        prevX = lightBox.viewportSize.x;
        prevY = lightBox.viewportSize.y;

        // Make it responsive (on viewport change)
        lightBox.listen('resize', function () {

            var resize = lightBox.viewportSize.x > prevX || lightBox.viewportSize.y > prevY;

            prevX = lightBox.viewportSize.x;
            prevY = lightBox.viewportSize.y;

            if (resize) {
                clearTimeout(resizeTimer);
                // Allwe need to do is invalidate the items as they will calculate and report their sizes
                resizeTimer = setTimeout(lightBox.invalidateCurrItems, 250);
            }
        });

        lightBox.init();
    });
}

$('.horizontal-gallery').each(function () {
 initGallery($(this));
})

// make it a jquery plugin
//$.fn.horizontalGallery = function () {
//    return this.each(function () {
//        initGallery($(this));
//    });
//}