/*eslint-disable*/
var ukjsconfig = {
    'ukjs1': {
        'hover': 'EAST ANGLIA',//info of the popup
        'url': '#eastanglia',//link to any webpage
        'target': 'modal', // use 'new_window', 'same_window', 'modal', or 'none'
        'upColor': '#fdfb02',//default color
        'overColor': '#d7d60b',//highlight color
        'downColor': '#8d8d06',//clicking color
        'active': true//true/false to activate/deactivate
    },
    'ukjs2': {
        'hover': 'EAST MIDLANDS',
        'url': '#eastmidlands', 'target': 'modal',
        'upColor': '#d21e1e', 'overColor': '#971717', 'downColor': '#3f0909',
        'active': true
    },
    'ukjs3': {
        'hover': 'LONDON',
        'url': '#london', 'target': 'modal',
        'upColor': '#202020', 'overColor': '#101010', 'downColor': '#000000',
        'active': true
    },
    'ukjs4': {
        'hover': 'NORTH EAST',
        'url': '#northeast', 'target': 'modal',
        'upColor': '#202020', 'overColor': '#101010', 'downColor': '#000000',
        'active': true
    },
    'ukjs5': {
        'hover': 'NORTHERN IRELAND',
        'url': 'none',
        'upColor': '#9c9c9c', 'overColor': '#9c9c9c', 'downColor': '#9c9c9c',
        'active': false
    },
    'ukjs6': {
        'hover': 'NORTH WEST',
        'url': '#northwest', 'target': 'modal',
        'upColor': '#5eccf8', 'overColor': '#49a0c3', 'downColor': '#387c97',
        'active': true
    },
    'ukjs7': {
        'hover': 'SCOTLAND',
        'url': 'none',
        'upColor': '#9c9c9c', 'overColor': '#9c9c9c', 'downColor': '#9c9c9c',
        'active': false
    },
    'ukjs8': {
        'hover': 'SOUTH EAST',
        'url': '#southeast', 'target': 'modal',
        'upColor': '#0127fb', 'overColor': '#0926c5', 'downColor': '#051672',
        'active': true
    },
    'ukjs9': {
        'hover': 'SOUTH WEST',
        'url': '#southwest', 'target': 'modal',
        'upColor': '#ed193a', 'overColor': '#93091f', 'downColor': '#3d000a',
        'active': true
    },
    'ukjs10': {
        'hover': 'WALES',
        'url': '#wales', 'target': 'modal',
        'upColor': '#fefefe', 'overColor': '#cfcfcf', 'downColor': '#9a9a9a',
        'active': true
    },
    'ukjs11': {
        'hover': 'WEST MIDLANDS',
        'url': '#westmidlands', 'target': 'modal',
        'upColor': '#84082b', 'overColor': '#490317', 'downColor': '#26010c',
        'active': true
    },
    'ukjs12': {
        'hover': 'YORKSHIRE',
        'url': '#yorkshire', 'target': 'modal',
        'upColor': '#fbc105', 'overColor': '#b68c05', 'downColor': '#725803',
        'active': true
    },
    'ukjs13': {
        'hover': 'IRELAND',
        'url': 'none',
        'upColor': '#9c9c9c', 'overColor': '#9c9c9c', 'downColor': '#9c9c9c',
        'active': false
    },
    'general': {
        'borderColor': '#000000',
        'visibleNames': '#adadad'
    }
};
/*eslint-disable*/
function isTouchEnabled() {
    return (('ontouchstart' in window)
        || (navigator.MaxTouchPoints > 0)
        || (navigator.msMaxTouchPoints > 0));
}
jQuery(function () {
    jQuery('path[id^=ukjs]').each(function (i, e) {
        ukaddEvent(jQuery(e).attr('id'));
    });
});
function ukaddEvent(id, relationId) {
    var _obj = jQuery('#' + id);
    var arr = id.split('');
    var _Textobj = jQuery('#' + id + ',' + '#ukjsvn' + arr.slice(4).join(''));
    jQuery('#' + ['visnames']).attr({ 'fill': ukjsconfig.general.visibleNames });
    _obj.attr({ 'fill': ukjsconfig[id].upColor, 'stroke': ukjsconfig.general.borderColor });
    _Textobj.attr({ 'cursor': 'default' });
    if (ukjsconfig[id].active === true) {
        _Textobj.attr({ 'cursor': 'pointer' });
        if (isTouchEnabled()) {
            _Textobj.on('touchstart', function (e) {
                var touch = e.originalEvent.touches[0];
                var x = touch.pageX + 10, y = touch.pageY + 15;
                var tipw = jQuery('#ukjstip').outerWidth(), tiph = jQuery('#ukjstip').outerHeight(),
                    x = (x + tipw > jQuery(document).scrollLeft() + jQuery(window).width()) ? x - tipw - (20 * 2) : x;
                y = (y + tiph > jQuery(document).scrollTop() + jQuery(window).height()) ? jQuery(document).scrollTop() + jQuery(window).height() - tiph - 10 : y;
                jQuery('#' + id).css({ 'fill': ukjsconfig[id].downColor });
                jQuery('#ukjstip').show().html(ukjsconfig[id].hover);
                jQuery('#ukjstip').css({ left: x, top: y });
            });
            _Textobj.on('touchend', function () {
                jQuery('#' + id).css({ 'fill': ukjsconfig[id].upColor });
                if (ukjsconfig[id].target === 'new_window') {
                    window.open(ukjsconfig[id].url);
                } else if (ukjsconfig[id].target === 'same_window') {
                    window.parent.location.href = ukjsconfig[id].url;
                } else if (ukjsconfig[id].target === 'modal') {
                    jQuery(ukjsconfig[id].url).modal('show');
                }
            });
        } else {
            _Textobj.hover(function () {
                jQuery('#ukjstip').show().html(ukjsconfig[id].hover);
                _obj.css({ 'fill': ukjsconfig[id].overColor });
            }, function () {
                jQuery('#ukjstip').hide();
                jQuery('#' + id).css({ 'fill': ukjsconfig[id].upColor });
            });
            if (ukjsconfig[id].target !== 'none') {
                _Textobj.mousedown(function () {
                    jQuery('#' + id).css({ 'fill': ukjsconfig[id].downColor });
                });
            }
            _Textobj.mouseup(function () {
                jQuery('#' + id).css({ 'fill': ukjsconfig[id].overColor });
                if (ukjsconfig[id].target === 'new_window') {
                    window.open(ukjsconfig[id].url);
                } else if (ukjsconfig[id].target === 'same_window') {
                    window.parent.location.href = ukjsconfig[id].url;
                } else if (ukjsconfig[id].target === 'modal') {
                    jQuery(ukjsconfig[id].url).modal('show');
                }
            });
            _Textobj.mousemove(function (e) {
                var x = e.pageX + 10, y = e.pageY + 15;
                var tipw = jQuery('#ukjstip').outerWidth(), tiph = jQuery('#ukjstip').outerHeight(),
                    x = (x + tipw > jQuery(document).scrollLeft() + jQuery(window).width()) ? x - tipw - (20 * 2) : x;
                y = (y + tiph > jQuery(document).scrollTop() + jQuery(window).height()) ? jQuery(document).scrollTop() + jQuery(window).height() - tiph - 10 : y;
                jQuery('#ukjstip').css({ left: x, top: y });
            });
        }
    }
}