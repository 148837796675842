import * as Cookies from 'js-cookie'

const $bar = $('#cookie-consent');
const $button = $('#consent-button');
const cookieName = 'CookieConsent';

const onClick = () => {
    Cookies.set(cookieName, true, { expires: 365 });
    $bar.fadeOut(250, () => { $bar.remove(); });
}
$button.click(onClick);